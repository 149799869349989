import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })
  export class BonoContratacionService {
    private apiUrl: string ='';
    private apiUrlGetBonoContratacion: string ='';
    private apiUrlPostBonoContratacion: string ='';
    private apiUrlEliminarBono: string ='';
    private apiUrlObtenerChoferes:string='';
    private apiUrlrpt:string='';
    constructor(private _http: HttpClient) {
      this.apiUrlGetBonoContratacion = environment.sUrlNomina + 'BonoContratacion/getBonoContratacion';
      this.apiUrlPostBonoContratacion = environment.sUrlNomina + 'BonoContratacion/PostBonoContratacion';
      this.apiUrlEliminarBono = environment.sUrlNomina + 'BonoContratacion/DeleteBono';
      this.apiUrlObtenerChoferes=environment.sUrlNomina+'BonoContratacion/getListChoferesCiudad';
      this.apiUrlrpt=environment.sUrlNomina+'BonoContratacion/rptbonocon';
    }

    getBonoPorSemanayCiudad(id_semana:number,anio:number,id_ciudad_principal_hm:number, cod_usuario: string): Observable<any[]> {
        const valores = {
          params: new HttpParams().
            set('num_semana', id_semana).
            set('anio', anio).
            set('id_ciudad_principal', id_ciudad_principal_hm).
            set('cod_usuario', cod_usuario)

        };
        return this._http.get<any[]>(this.apiUrlGetBonoContratacion, valores).pipe(retry(1), catchError(this.handleError));
      }

      getListChoferesCiudad(id_semana: number, id_ciudad_hm:number): Observable<any[]> {
        const valores = {
          params: new HttpParams().
             set('id_semana',id_semana).
             set('id_ciudad_principal_hm',id_ciudad_hm)
        }
        return this._http
            .get<any[]>(this.apiUrlObtenerChoferes,valores)
            .pipe(retry(1), catchError(this.handleError));
       }

      PostBonoContratacion(data:any[]):Observable<any>{
        const headers = { 'content-type': 'application/json'}
        return this._http.post<any>(this.apiUrlPostBonoContratacion,data,{headers}).pipe(retry(1), catchError(this.handleError));
      }

      DeleteBono(data:any):Observable<any>{
        return this._http.put<any>(this.apiUrlEliminarBono,data).pipe(retry(1), catchError(this.handleError));
      }





          rptbono(num_anio: number, id_semana: number,id_ciudad_principalrpt : number): Observable<any[]> {
            const valores = {
              params: new HttpParams()
                .set('num_anio', num_anio)
                .set('id_semana', id_semana)
                .set('id_ciudad_principal', id_ciudad_principalrpt )

            }
            return this._http
                .get<any[]>(this.apiUrlrpt,valores).pipe(retry(1), catchError(this.handleError));

           }


      handleError(error:any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
      }
}
