<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
  [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
  <div class="container-fluid" style="width: 98%;">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card card-shadow sinborde">
          <div class="card-header sinborde" style="background: none; ">
            <div class="cardicono rounded">
              <i class="pi pi-calendar-plus mt-3" style="font-size: 2rem; color: #fff;"></i>
            </div>
            <header style="left: 11%; top: 5%;">
              <div style="display:inline">Gestión de</div>
              <div style="color:rgb(226, 101, 43);display:inline;" class="ml-3">Descuentos Varios</div>
            </header>
            <div class="row mt-6 ml-6 d-flex justify-content-between">

              <!-- -------------fecha del filtro---------------- -->
              <div class="form-group col-md-6 d-flex">
                <!-- filtro de semanas -->
                <div class="col-md-5">
                  <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                    placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                    [showClear]="false" [disabled]="lstSemanas.length == 0">
                    <ng-template let-target pTemplate="item">
                      <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                        pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                        <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <div *ngIf="selectedSemana!=null">
                    <div class="row">
                      <div style="color: green;" class="col-sm-6 text-left ">
                        <b>Inicio:
                          {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy' }}</b>
                      </div>
                      <div style="color: green;" class="col-sm-6 text-right "><b>Fin:
                          {{selectedSemana.fec_fin | date: 'dd/MM/yyyy' }}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- filtro de ciudades -->
                <div class="col-md-3 ml-4">
                  <p-dropdown [options]="lstCiudad_filtro" optionLabel="nombre" optionValue="id"
                    [(ngModel)]="selectedCiudad" placeholder="Filtrar por Ciudad" [showClear]="false"
                    [disabled]="lstSemanas.length == 0">
                  </p-dropdown>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2">
                  <p-button label="Filtrar" icon="pi pi-list" iconPos="right" (click)="cargarDescuentosVarios()"
                    [disabled]="lstSemanas.length == 0">
                  </p-button>
                </div>

              </div>
              <div class="form-group col-md-4">

                <div class="row d-flex justify-content-start">
                  <div class="row d-flex justify-content-start">
                    <div class="col-md-3 "></div>
                    <div class="col-md-4 ml-4">
                      <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                        inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                        pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                    </div>
                    <div class="col-md-3">
                      <button pButton pRipple label="Cargar" class="p-button-help" icon="pi pi-filter"
                        (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);" pTooltip="Cargar Semanas del Año"
                        tooltipPosition="top"></button>
                    </div>
                  </div>
                </div>

              </div>

              <!-- ----------boton principal de los reportes en excel------------------- -->
              <div class="  col-md-1 ">
                <!-- <button type="button" pButton pRipple class="p-button-success " [disabled]="lstSemanas.length == 0"
                  pTooltip="Exportar Catalogo a EXCEL" tooltipPosition="right" (click)="abrirModalReportes()">
                  <i class="pi pi-file-excel"></i> Exportar
                </button> -->
              </div>
              <div class="col-md-1"></div>

            </div>
            <div class="card-content">
              <div class="row mt-3">
                <p-table #dt1 [value]="lstDescuentosVarios" [tableStyle]="{'min-width': '50rem'}"
                  styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="480px"
                  responsiveLayout="scroll" [globalFilterFields]="['cod_chofer','nombre_completo','desc_ciudad']"
                  [paginator]="true" [rows]="30" [rowsPerPageOptions]="[10,15,20,30,50,100]"
                  [showCurrentPageReport]="true" [resizableColumns]="false"
                  currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros" [loading]="loading">
                  <ng-template pTemplate="caption">
                    <div class="flex justify-content-between">
                      <div class="col-md-3">
                        <button pButton pRipple label="Agregar nuevo registro" class="btn btn-success" icon="pi pi-plus"
                          (click)="registrarDescuento();" pTooltip="Registro Descuento" tooltipPosition="right"
                          iconPos="right"
                          [disabled]="lstSemanas.length == 0 || dspCalculo || dspDialogoDispercion"></button>
                      </div>
                      <div class="  col-md-2">
                        <button type="button" pButton pRipple class="p-button-success "
                          [disabled]="lstSemanas.length == 0" pTooltip="Exportar a EXCEL"
                          tooltipPosition="right" (click)="abrirModalReportes()">
                          <i class="pi pi-file-excel"></i> Exportar
                        </button>
                      </div>
                      <div class="  col-md-4">

                      </div>

                      <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                          placeholder="Buscar Codigo Colaborador,Nombre Colaborador,Ciudad de Operación" />
                      </span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" pSortableColumn="cod_chofer">#<p-sortIcon field="cod_chofer"></p-sortIcon>
                      </th>
                      <th class="text-center" pSortableColumn="nombre_completo">Colaborador<p-sortIcon
                          field="nombre_completo"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_ciudad">Ciudad<p-sortIcon
                          field="desc_ciudad"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_categoria">Categoria<p-sortIcon
                          field="desc_categoria"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_sub_categoria">Sub Categoria<p-sortIcon
                          field="desc_sub_categoria"></p-sortIcon></th>
                      <th class="text-center">Motivo del descuento</th>
                      <th class="text-center" pSortableColumn="imp_total">Importe Total<p-sortIcon
                          field="imp_total"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="id_semana_inicial">Semana Inicial<p-sortIcon
                          field="id_semana_inicial"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="num_pagos">Pagos Acordados<p-sortIcon
                          field="num_pagos"></p-sortIcon></th>
                      <th class="text-center" pSortableColumn="desc_estatus">Estatus<p-sortIcon
                          field="desc_estatus"></p-sortIcon></th>
                      <th class="text-center">Reporte PDF</th>
                      <th class="text-center">Reporte EXCEL</th>
                      <th class="text-center">Dispersión</th>
                      <th class="text-center">Acciones</th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data let-index>
                    <tr>
                      <td class="text-center"> <b>{{data.cod_chofer}}</b></td>
                      <td><b>{{data.nombre_completo }}</b> </td>
                      <td class="text-center"><b>{{data.desc_ciudad}}</b></td>
                      <td class="text-center texto_principal">{{data.desc_categoria}}</td>
                      <td class="text-center" style="color: #2a99bb;"><b>{{ data.desc_sub_categoria}}</b> </td>


                      <td class="text-justify" style="width: 200px; min-width: 200px; font-size:13px;">
                        {{data.desc_motivo | slice : 0:80 }}<button type="button" class="btn p-button-text"
                          (click)="verMas(data);" pTooltip="ver mas" tooltipPosition="top">
                          <i class="pi pi-comment color-icon mr-3" style="font-size: 1rem; color: #3B82F6;"></i>
                        </button></td>

                      <td class="text-center" style="color: rgb(202, 35, 35);"><b>{{ data.imp_total | currency}}</b>
                      </td>
                      <td class="text-center " style="color: #f0ad38;"><b>{{ data.id_semana_inicial}}</b></td>
                      <td class="text-center" style="color: #2ed249;"><b>{{data.num_pagos}}</b></td>
                      <td class="text-center texto_principal"
                        [ngStyle]="{'color': data.desc_estatus==='ACTIVO' ? 'rgb(28, 163, 35)' : 'rgb(224, 82, 60)'}">
                        {{ data.desc_estatus}}</td>

                      <td>
                        <div class="d-flex justify-content-center">
                          <i class="pi pi-file-pdf" style="font-size: 1.5rem; color: rgb(189, 12, 12); cursor: pointer;"
                            (click)="reciboPDF(data)" tooltipPosition="top" pTooltip="Descargar Recibo">
                          </i>
                        </div>
                      </td>

                      <td>
                        <div class="d-flex justify-content-center">
                          <i class="pi pi-file-excel"
                            style="font-size: 1.5rem; color: rgb(68, 189, 12); cursor: pointer;"
                            (click)="reciboECxcel(data)" tooltipPosition="top" pTooltip="Exportar excel">
                          </i>
                        </div>
                      </td>


                      <td class="text-center texto_principal">
                        <button class=" ml-2 btn btn-light" tooltipPosition="top"
                          (click)="mostrarDialogoDispersion(data)" pTooltip="Desgloce de la dispersion"
                          [disabled]="dspCalculo || dspDialogoDispercion">
                          <i class="pi pi-info-circle color-icon" style="font-size: 1.5rem; color: #39a4e2;">
                          </i></button>


                      </td>
                      <td class="texto_principal">
                        <button *ngIf="data.clv_btn_eliminar == 1" class="btn btn-danger w-100"
                          pTooltip="Eliminar Asignación de Bono" [disabled]="dspCalculo || dspDialogoDispercion"
                          (click)="deleteDescuento(data.id_descuento,data.nombre_completo)">
                          <span class="text-white">Eliminar</span>
                          <i class="pi pi-save text-white"></i>
                        </button>
                      </td>
                      <td></td>
                    </tr>
                  </ng-template>
                  <!-- <ng-template pTemplate="summary">
                                        <div class="flex align-items-center justify-content-center mb-2 mt-2">
                                            <div class="col-md-3">
                                                <button class="btn btn-success w-100"
                                                    pTooltip="Guardar Asignación de Bono" (click)="guardarTodo()">
                                                    <span class="text-white">Guardar Todo</span>
                                                    <i class="pi pi-save text-white"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template> -->
                </p-table>

                <div class=" mt-3 d-flex justify-content-center">
                  <p class="text-left informativo"
                    style="display: inline; padding-left: 10px; border-left: 5px solid rgb(28, 163, 35); font-weight: normal; font-size: 0.8rem;">
                    <b>ACTIVO</b> la deuda de la dispersion continua activa.
                  </p>
                  <p class="text-left informativo"
                    style="display: inline; padding-left: 10px; border-left: 5px solid rgb(224, 82, 60); font-weight: normal; font-size: 0.8rem;">
                    <b>FINALIZADO</b> la dedua de la dispersion ya fue saldada.
                  </p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--AGREGAR / EDITAR PRESTAMO-->
      <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspCalculo"
        [style]="{width: '75vw','height':'880px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModal()">
        <ng-template pTemplate="header">
          {{ texto_operacion }}
          <div *ngIf="" style="color:black;">
            {{ nombre_registro }}
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="mt-2">
            <form [formGroup]="dataFormChofer">
              <span class="ml-4 title-light mt-3">Información General<i class="pi pi-cog" style="size: 2rem;"></i>
              </span>
              <div class="row mt-3">
                <div class="form-group">
                  <div class="row g-0 mt-3 ml-4">
                    <!-- Primer Dropdown -->
                    <div class="col-sm-2 mx-0 mb-4 mt-2">
                      <div class="input-group">
                        <i class="pi pi-user color-icon" style="font-size: 1.5rem"></i>
                        <span class="ml-3 align-self-start"><b>Colaborador</b></span>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-4">
                      <p-dropdown [options]="lstDescuentosVariosChoferes" autoWidth="false" [style]="{'width':'90%'}"
                        placeholder="Seleccione Colaborador*" optionLabel="nombre_completo" [showClear]="true"
                        [filter]="true" filterBy="nombre_completo" formControlName="cod_chofer" optionValue="cod_chofer"
                        tooltip="">
                        <ng-template let-item pTemplate="selectedItem">
                          <span style="vertical-align:middle; word-wrap: break-word;"
                            class="texto_responsable">{{item.nombre_completo}}</span>
                        </ng-template>
                        <ng-template let-target pTemplate="item">
                          <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                            pTooltip="{{target.desc_ciudad_hm}}" tooltipPosition="top">
                            <span
                              style="font-size:14px;float:left;margin-top:4px;word-wrap: break-word;">{{target.nombre_completo}}</span>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                    <!-- Segundo Dropdown -->
                    <div class="col-sm-2 mx-0 ml-2 mb-4 mt-2">
                      <div class="input-group">
                        <i class="pi pi-building color-icon" style="font-size: 1.5rem"></i>
                        <span class="ml-3 align-self-start"><b>Ciudad</b></span>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-4">
                      <p-dropdown [options]="lstCiudad" autoWidth="false" [style]="{'width':'90%'}"
                        placeholder="Seleccione Ciudad Operación*" optionLabel="nombre" [showClear]="true"
                        [filter]="true" formControlName="id_ciudad_hm" optionValue="id" tooltip="">
                      </p-dropdown>
                    </div>
                    <!-- Tercer Dropdown -->
                    <div class="col-sm-2 mx-0 mb-4 mt-2">
                      <div class="input-group">
                        <i class="pi pi-percentage color-icon" style="font-size: 1.5rem"></i>
                        <span class="ml-3 align-self-start"><b>Descuento</b></span>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-4">
                      <p-dropdown [options]="lstTipoDescuentos" autoWidth="false" [style]="{'width':'90%'}"
                        placeholder="Seleccione Tipo Descuento*" optionLabel="desc_descuento" [showClear]="true"
                        [filter]="false" formControlName="id_categoria_descuento" optionValue="id_descuento" tooltip="">
                      </p-dropdown>
                    </div>
                    <!-- Cuarto Dropdown -->
                    <div class="col-sm-2 mx-0 mb-4 mt-2">
                      <div class="input-group">
                        <i class="pi pi-percentage color-icon" style="font-size: 1.5rem"></i>
                        <span class="ml-3 align-self-start"><b>Sub-Descuento</b></span>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-4">
                      <p-dropdown [options]="lstSubTipoDescuentos" autoWidth="false" [style]="{'width':'90%'}"
                        placeholder="Seleccione Tipo Sub Descuento*" optionLabel="desc_sub_descuento" [showClear]="true"
                        [filter]="false" formControlName="id_sub_categoria_descuento" optionValue="id_sub_descuento"
                        tooltip="">
                      </p-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="mt-2">
            <form [formGroup]="dataFormCalc" (ngSubmit)="calcularDispersion()">
              <span class="ml-4 title-light mt-3">Calculo Dispersión<i class="pi pi-cog" style="size: 2rem;"></i>
              </span>

              <div class="form-group">
                <div class="row mt-3">
                  <div class="col-md-6">

                    <div class="row g-0 mt-3 ml-4 mr-4">
                      <!-- Campo Importe Total Descuento -->
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-11 ml-2 mt-4">
                            <label style="color: rgb(22, 22, 176);" class="label-header">
                              <i class="pi pi-money-bill color-icon" style="font-size: 1.5rem;"></i><b> Total</b>
                            </label>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="d-flex align-items-center">

                            <input type="number" class="form-control sinborde ml-2" pTooltip="Importe Total Descuento"
                              min="1" placeholder="Importe Total Descuento*" formControlName="imp_total"
                              [style]="{'width':'95%'}">
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-11 ml-2 mt-4">
                            <label style="color: rgb(22, 22, 176);" class="label-header">
                              <i class="pi pi-tags color-icon" style="font-size: 1.5rem;"></i>
                              <b> Num. Pagos*</b>
                            </label>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="d-flex align-items-center">

                            <input type="number" class="form-control sinborde ml-2" placeholder="Numero de Pagos*"
                              min="1" pTooltip="Numero de Pagos" formControlName="num_pagos" [style]="{'width':'95%'}">
                          </div>
                        </div>
                      </div>






                      <div class="row mt-3">


                        <!-- Campo Fecha Descuento -->
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-11 mt-4 ">
                              <label style="color: rgb(22, 22, 176);" class="label-header">
                                <b>Fecha del incidente*</b>
                              </label>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <input type="date" formControlName="fec_incidente" placeholder="Seleccione una fecha"
                              [style]="{'width':'95%', 'height':'40px', 'padding':'10px', 'border':'none', 'outline':'none', 'background-color':'transparent'}"
                              pTooltip="Fecha a descuento" />
                          </div>

                        </div>

                        <div class="col-md-6 ">
                          <div class="row">
                            <div class="col-md-11 mt-4 ml-2">
                              <label style="color: rgb(22, 22, 176);" class="label-header"><b>Inicio
                                  descuento</b></label>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <!-- <div class="col-md-12   "> -->
                            <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'95%'}"
                              placeholder="Seleccione Semana*" formControlName="selectedSemanaagregar"
                              optionLabel="nom_semana" [showClear]="false">
                              <ng-template let-target pTemplate="item">
                                <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                                  pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                                  <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                                </div>
                              </ng-template>
                            </p-dropdown>
                            <div *ngIf="dataFormCalc.controls['selectedSemanaagregar'].value.id_semana!=null">
                              <div class="row ">
                                <div style="color: green;" class="col-md-6 d-flex justify-content-start ml-3">
                                  <b>Inicio:
                                    {{dataFormCalc.controls['selectedSemanaagregar'].value.fec_inicio | date:
                                    'dd/MM/yyyy' }}</b>
                                </div>
                                <div style="color: green;" class="col-md-5 d-flex justify-content-end mr-2"><b>Fin:
                                    {{dataFormCalc.controls['selectedSemanaagregar'].value.fec_fin | date:
                                    'dd/MM/yyyy'
                                    }}</b></div>
                              </div>
                            </div>
                            <!-- </div> -->
                          </div>

                        </div>



                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-md-6">
                    <div class="row mt-3 ml-2">
                      <label style="color:  rgb(22, 22, 176); font-weight: bold;">Motivo del descuento</label>
                      <textarea rows="5" cols="58" style="width: 96%;" pInputTextarea
                        formControlName="desc_motivo"></textarea>
                    </div>
                  </div>



                </div>
                <div class="row mt-4">
                  <div class="col-md-6 d-flex justify-content-center"> <!-- Flex para alinear en una línea -->
                    <div class="col-md-5"> <!-- Ajusta el tamaño de la columna -->
                      <div class="row">
                        <label style="color: rgb(22, 22, 176); font-weight: bold;">Jefe inmediato</label>
                      </div>
                      <div class="row">
                        <input type="text" class="form-control sinborde"
                          placeholder="Ingrese el nombre del jefe inmediato" pTooltip="Jefe inmediato del colaborador"
                          formControlName="nom_jefe" [style]="{'width':'95%'}">
                      </div>
                    </div>

                    <div class="col-md-5 ml-5"> <!-- Ajusta el tamaño de la columna -->
                      <div class="row">
                        <label style="color: rgb(22, 22, 176); font-weight: bold;">Gerente del área</label>
                      </div>
                      <div class="row">
                        <input type="text" class="form-control sinborde" placeholder="Ingrese el nombre del gerente"
                          pTooltip="Gerente de área del colaborador" formControlName="nom_gerente"
                          [style]="{'width':'95%'}">
                      </div>
                    </div>
                  </div>
                </div>





              </div>


              <div class="row d-flex justify-content-center mt-7">
                <div class="col-2">
                  <button type="submit" class="btn btn-primary w-100"
                    [disabled]="!dataFormCalc.valid || !dataFormChofer.valid ">
                    <span class="text-white">Calcular </span>
                    <i class="pi pi-percentage text-white"></i>
                  </button>
                </div>
                <!-- <div class="col-2">
                                    <button type="submit" class="btn btn-success w-100"
                                        [disabled]="!dataFormChofer.valid &&lstDispersion.length == 0">
                                        <span class="text-white">Guardar </span>
                                        <i class="pi pi-save text-white"></i>
                                    </button>
                                </div> -->
              </div>
            </form>
            <div *ngIf="lstDispersion.length>0">
              <p-table [value]="lstDispersion" styleClass="p-datatable-sm p-datatable-striped"
                [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="caption">
                  <div class="flex justify-content-start">
                    <span class="p-input-icon-left p-ml-auto">
                      <p> Dispersión del Descuento</p>
                    </span>
                  </div>
                </ng-template>
                <!-- <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center">#Pago</th>
                    <th class="text-center">Semana de Abono</th>
                    <th class="text-center">Año</th>
                    <th class="text-center">Importe</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                  <tr>
                    <td class="text-center" style="color: black;"><b>{{ data.id_periodo }}</b></td>
                    <td class="text-center " style="color: rgb(23, 212, 23);"><b>{{ data.id_semana }}</b></td>
                    <td class="text-center texto_principal">{{ data.num_anio}}</td>
                    <td class="text-center" style="color: rgb(198, 54, 54);"><b>{{data.imp_periodo | currency }}</b>
                    </td>
                  </tr>
                </ng-template> -->
                <!-- <p-table [value]="products1" dataKey="id" [tableStyle]="{'min-width': '50rem'}"> -->
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center">#Pago</th>
                    <th class="text-center">Semana de Abono</th>
                    <th class="text-center">Año</th>
                    <th class="text-center">Importe</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                  <!-- formcontrolname="imp_periodo" > -->
                  <tr>
                    <td class="text-center" style="color: black;"><b>{{ data.id_periodo }}</b></td>
                    <td class="text-center " style="color: rgb(23, 212, 23);"><b>{{ data.id_semana }}</b></td>
                    <td class="text-center texto_principal">{{ data.num_anio}}</td>
                    <td class="text-center " pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="number" [(ngModel)]="data.imp_periodo" />
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{ data.imp_periodo | currency }}
                        </ng-template>
                      </p-cellEditor>
                    </td>

                  </tr>
                </ng-template>
                <!-- </p-table> -->
                <ng-template pTemplate="footer">
                  <tr>
                    <td colspan="3" class="text-right">Total Dispersión</td>
                    <td>{{ total_dispersion | currency: 'USD'}}</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div *ngIf="lstDispersion.length>0"
                    class="flex align-items-center justify-content-center mb-2 mt-2 ml-4 mr-4">
                    <div class="col-md-3">
                      <button class="btn btn-success w-100" pTooltip="Guardar Dispersion Descuento"
                        [disabled]="!dataFormCalc.valid|| !dataFormChofer.valid &&lstDispersion.length == 0"
                        (click)="guardarDispersion()">
                        <span class="text-white">Registrar Descuento </span>
                        <i class="pi pi-save text-white"></i>
                      </button>
                    </div>
                  </div>
                </ng-template>
              </p-table>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarModal();"></button>
        </ng-template>
      </p-dialog>
      <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspDialogoDispercion"
        [style]="{width: '65vw','height':'650px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
        (onHide)="cancelarModalEstatus()">
        <ng-template pTemplate="header">
          {{ texto_operacion }}
          <div *ngIf="" style="color:black;">
            {{ nombre_registro }}
          </div>
        </ng-template>

        <span class="title-light mt-3">Información de Pagos <i class="pi pi-cog" style="size: 2rem;"></i>
        </span>

        <ng-template pTemplate="content">
          <div class="mt-2">

            <div>
              <p-table [value]="lstTableMostrarDispersion.jsnDescDisp" styleClass="p-datatable-sm p-datatable-striped"
                [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="caption">


                  <div class="row mt-1 mb-1 d-flex justify-content-around ">
                    <div class="col-md-2 ">#Chofer : &nbsp;<label style="color: #344ba5;"><b>{{cod_chofer}}</b></label>
                    </div>
                    <div class=" col-md-5 ">Colaborador : &nbsp;<label
                        style="color:#344ba5;"><b>{{nombre_completo}}</b></label></div>
                    <div class="col-md-3 ">Deuda Restante: &nbsp;<label style="color: #e69915;"><b>{{imp_restante |
                          currency}}</b></label></div>
                    <div class="col-md-2">Deuda total: &nbsp;<label style="color: #e62929;"><b>{{imp_total |
                          currency}}</b></label>
                    </div>


                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th>#Num.periodo</th>

                    <th class="text-center">Categoria</th>
                    <th class="text-center">Sub-Categoria</th>
                    <th class="text-center">Importe de Abono</th>
                    <th class="text-center">Semana de Descuento</th>
                    <!--<th class="text-center">Importe Pagado</th>-->
                    <th class="text-center">Estatus de Pago</th>
                    <th class="text-center">Accion</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                  <tr>
                    <td class="text-center" style="color: black;"><b>{{data.id_periodo}}</b></td>
                    <td class="text-center texto_principal">{{data.desc_descuento}}</td>
                    <td class="text-center" style="color: #45c8f0;"><b>{{data.desc_sub_descuento}}</b></td>
                    <td class="text-center" style="color: rgb(226, 42, 42);"><b>{{data.imp_periodo | currency}}</b></td>
                    <td class="text-center " style="color: black;"><b>{{data.id_semana}}</b></td>
                    <td class="text-center " style="color: rgb(0, 0, 0);" *ngIf="data.clv_pago == 0">
                      <b>{{data.desc_estatus}}</b>
                    </td>
                    <td class="text-center " style="color: rgb(40, 216, 40);" *ngIf="data.clv_pago == 1">
                      <b>{{data.desc_estatus}}</b>
                    </td>
                    <td class="text-center">
                      <div class="ml-2">
                        <button [disabled]="data.clv_pago === 1" class="btn"
                          [ngClass]="{'btn-success': data.clv_pago === 0, 'btn-link': data.clv_pago === 1}"
                          tooltipPosition="left" pTooltip="{{data.clv_activo === 0 ? 'ya fue pagada ' : 'Pagar' }}"
                          (click)="cambioEstatus(lstTableMostrarDispersion.id_descuento,data.id_periodo, data)">
                          <i class="pi pi-wallet"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="cancelarModalEstatus();"></button>
        </ng-template>
      </p-dialog>

      <!-- pdialog del motivo del descuento -->
      <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dspVerMas"
        [style]="{width: '35vw','height':'500px'}" (onHide)="verMenos()" [transitionOptions]="'0ms'"
        styleClass="card-shadow">
        <ng-template pTemplate="header">
          Motivo del descuento
        </ng-template>
        <ng-template pTemplate="content">
          <form [formGroup]="dataFormCalc" (ngSubmit)="verMenos()">
            <div class="row  pb-2">

              <!-- tipo de incapacuidad -->

              <div class="row d-flex mt-3">
                <label style=" font-weight: bold;" class="title-light">Colaborador: {{this.nombre_completo}}</label>
                <label style=" font-weight: bold;">Jefe directo: {{this.nom_jefe}}</label>
                <label style=" font-weight: bold;">Fecha del incidente: {{this.fec_incidente}}</label>
                <textarea rows="5" cols="50" pInputTextarea formControlName="desc_motivo"></textarea>
              </div>
            </div>
            <div class="mt-2 d-flex justify-content-center">
              <button type="submit" class="btn btn-lg btn-success">ACEPTAR</button>
            </div>
          </form>
        </ng-template>
        <ng-template pTemplate="footer">
          <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
            (click)="verMenos();"></button>
        </ng-template>
      </p-dialog>

    </div>

    <!-- p-dialog de reportes excel ------------ -->
    <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
      [style]="{'width': '85vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
      (onHide)="cancelarModalReportes()">
      <ng-template pTemplate="header">
        Generar Reporte De Excel
      </ng-template>
      <ng-template pTemplate="content">
        <div class="mt-5">
          <hr class="style-two">
          <div class="row">
            <div class="col-md-3  d-flex align-items-center">
              <i class="pi pi-file-excel mr-2" style="color: green;  font-size: 1.8rem;"></i>
              <p style="margin-left: 8px;" class="texto-pesado mt-2"><b>Reporte de deducciones de descuentos</b></p>
          </div>
            <!-- <div class="col-md-3">
              <p class="texto-pesado">Reporte de deducciones de descuentos</p>
            </div> -->
            <div class="col-md-4">
              <p-dropdown styleClass="small-dropdown" [options]="lstSemanas" autoWidth="false"
                [style]="{'width':'100%'}" placeholder="Seleccione Semana*" [(ngModel)]="selectedSemanaReporte"
                optionLabel="nom_semana" [showClear]="false">
                <ng-template let-target pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                    pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                    <span style="font-size:14px;float:left;margin-top:4px">{{target.nom_semana}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="selectedSemanaReporte!=null">
                <div class="row">
                  <div style="color: green;" class="col-sm-6 text-left  " [ngClass]="{'texto_sm':true}"><b>Inicio:
                      {{selectedSemanaReporte.fec_inicio | date: 'dd/MM/yyyy' }}</b></div>
                  <div style="color: green;" class="col-sm-6 text-right " [ngClass]="{'texto_sm':true}"><b>Fin:
                      {{selectedSemanaReporte.fec_fin | date: 'dd/MM/yyyy' }}</b></div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <p-dropdown styleClass="small-dropdown" [(ngModel)]="id_ciudad_hm_rpt" [options]="lstCiudad_filtro"
                optionLabel="nombre" optionValue="id" placeholder="Filtrar por Ciudad" [showClear]="false" [style]="{'width':'100%'}" >
              </p-dropdown>
            </div>
            <div class="col-md-1 ml-6">
              <button type="button" [disabled]="deshabilitarbtnexportar" class="btn btn-outline-success"
                style="width: 100%;" (click)="exportarExcel()" [disabled]="ButtonExportExcel">
                Exportar <i class="pi pi-file-export"></i>
              </button>
            </div>
          </div>
          <hr class="style-two">

          <!-- Reporte por colaborador -->
          <div>

            <div class="row">
              <div class="col-md-3  d-flex align-items-center">
                <i class="pi pi-file-excel mr-2" style="color: green;  font-size: 1.8rem;"></i>
                <p style="margin-left: 8px;" class="texto-pesado mt-2"><b>Reporte por colaborador</b></p>
            </div>
            <!-- <div class="row">
              <div class="col-md-3">
                <b><p class="texto-pesado">Reporte por colaborador</p></b>
              </div> -->


              <div class=" row col-md-4">
                <!-- <div class="col-md-9">
                  <p-dropdown [(ngModel)]="cod_chofer" [options]="lstDescuentosVariosChoferes"
                    optionLabel="nombre_completo" optionValue="cod_chofer" placeholder="Filtrar por colaborador"
                    [showClear]="false" styleClass="small-dropdown"  [style]="{'width':'100%'}">

                  </p-dropdown>
                </div> -->


                <div class="col-md-9">
                  <p-dropdown [options]="lstDescuentosVariosChoferes" autoWidth="false" [style]="{'width':'100%'}" styleClass="small-dropdown"
                    placeholder="Seleccione Colaborador*" optionLabel="nombre_completo" [showClear]="false"
                    [filter]="true" filterBy="nombre_completo" [(ngModel)]="cod_chofer" optionValue="cod_chofer"
                    tooltip="">
                    <ng-template let-item pTemplate="selectedItem">
                      <span style="vertical-align:middle; word-wrap: break-word;"
                        class="texto_responsable">{{item.nombre_completo}}</span>
                    </ng-template>
                    <ng-template let-target pTemplate="item">
                      <div class="ui-helper-clearfix" style="position: relative;height:25px;"
                        pTooltip="{{target.desc_ciudad_hm}}" tooltipPosition="top">
                        <span
                          style="font-size:14px;float:left;margin-top:4px;word-wrap: break-word;">{{target.nombre_completo}}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>



                <div class="col-md-3 mt-2 d-flex justify-content-center">
                  <button type="submit" (click)="GetListDesc()" class="btn btn-primary">Cargar</button>
                </div>


              </div>
              <div class="col-md-3 ml-4">
                <p-dropdown [(ngModel)]="id_descuento" [options]="lstdesclist" [disabled]="cargaroff"
                  optionLabel="desc" optionValue="id_descuento" placeholder="Seleccione un descuento*"
                  [showClear]="false" styleClass="small-dropdown" [style]="{'width':'100%'}" >
                </p-dropdown>

              </div>


              <div class="col-md-1 ml-6">
                <button type="button" [disabled]="deshabilitarbtnexportar" class="btn btn-outline-success"
                  style="width: 100%;" (click)="exportarExcelindividual()" [disabled]="ButtonExportExcel || cargaroff"  >
                  Exportar <i class="pi pi-file-export"></i>
                </button>
              </div>
            </div>




          </div>
          <div class="row d-flex justify-content-center">
            <div class="col-2 text-center">
              <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
          (click)="cancelarModalReportes();"></button>
      </ng-template>
    </p-dialog>
  </div>
