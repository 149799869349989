import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ITabuladoSueldo } from '../models/tabuladosueldo';
import { ITabuladoReestructura } from '../models/tabulado_reestructura';

@Injectable({
  providedIn: 'root'
})
export class TabuladoService {
  private apiUrl: string ='';
  private apiUrl_rees: string ='';
  constructor(private http: HttpClient) {
    this.apiUrl = environment.sUrlNomina + 'TabuladorSueldosCiudad';
    this.apiUrl_rees = environment.sUrlNomina + 'TabuladorReestructura';
  }

  getListadoTabulado(num_anio:number): Observable<ITabuladoSueldo[]> {
    return this.http
      .get<ITabuladoSueldo[]>(this.apiUrl+'/'+num_anio)
      .pipe(retry(1), catchError(this.handleError));
  }

  getListadoTabuladoReestructura(num_anio:number , user:string): Observable<any[]> {
    const valores = {
      params: new HttpParams().
        set('anio', num_anio).
        set('user', user)
    };
    return this.http
      .get<any[]>(this.apiUrl_rees+'/GetTabuladoReestructura/',valores)
      .pipe(retry(1), catchError(this.handleError));
  }

  public agregarReestructuraNoOrdinario(data: any ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl_rees+'/SaveTabuladoReestructura',body,{'headers':headers});
  }

  public agregarReestructuraOperaciones(data: any ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl_rees+'/SaveTabuladoReestructura',body,{'headers':headers});
  }



  public agregar(data: ITabuladoSueldo ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.post<any>(this.apiUrl,body,{'headers':headers});
   }

   public editar(data: ITabuladoSueldo ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl,body,{'headers':headers});
   }

   public estatus(data: ITabuladoReestructura ):Observable<any>{
    const headers = { 'content-type': 'application/json'}
    const body = JSON.stringify(data);
    return this.http.put<any>(this.apiUrl_rees+'/editarStatus',body,{'headers':headers});
   }

   public listarRutasNormales():Observable<any>{
    return this.http.get(this.apiUrl_rees+'/getListNormal');
   }

  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
        return errorMessage;
    });
  }
}
