import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IApoyo } from '../models/apoyo';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { CiudadesprincipalesService } from './ciudadesprincipales.service';
import { ICatApoyo } from '../models/catapoyos';
import { InombreCompletoChoferciudadprincipal } from '../models/nombrecompletociudadprincipal';
import { IPostApoyo } from '../models/postapoyo';
import { IsalarioPromedio } from '../models/salariopromedio';

@Injectable({
  providedIn: 'root'
})
export class SalarioPromedioService{

  private apiUrl: string = environment.sUrlNomina + 'SalarioPromedio';

  constructor(private http: HttpClient, private ciudadesPrincipalesService: CiudadesprincipalesService) { }  // Inyección de CiudadesprincipalesService


 getListadoSalarioPromedio(num_anio: number,id_semana:number ,id_ciudad_hm: number): Observable<IsalarioPromedio[]> {
  const valores = {
    params: new HttpParams().
       set('num_anio',num_anio).
       set('id_semana',id_semana).
       set('id_ciudad_hm',id_ciudad_hm)

  }
  return this.http
      .get<IsalarioPromedio[]>(this.apiUrl +'/getSalarioPromedio',valores)
      .pipe(retry(1), catchError(this.handleError));

 }

 getrptSalarioPromedio(num_anio: number,id_semana:number ,id_ciudad_hm: number): Observable<any[]> {
  const valores = {
    params: new HttpParams().
       set('num_anio',num_anio).
       set('id_semana',id_semana).
       set('id_ciudad_hm',id_ciudad_hm)

  }
  return this.http
      .get<any[]>(this.apiUrl +'/rptsalariopromedio',valores)
      .pipe(retry(1), catchError(this.handleError));

 }



  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

}
