import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { IChoferDisponible } from '../models/choferdisponible';
import { IMes } from '../models/mes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICiudadHM } from '../models/ciudadhm';
import ICredencialUsuario from '../models/credencial';
import { AuthService } from '../services/auth.service';
import { ISemanasHappyMile } from '../models/semanas';
import { SemanaService } from '../services/semana.service';
import { ICiudadPrincipalHM } from '../models/ciudadprincipal';
import { ApoyoService } from '../services/apoyo.service';
import { IApoyo } from '../models/apoyo';
import { ICatApoyo } from '../models/catapoyos';
import { InombreCompletoChoferciudadprincipal } from '../models/nombrecompletociudadprincipal';
import { IDiasApoyo } from '../models/diasapoyo';
import { IPostApoyo } from '../models/postapoyo';
import * as saveAs from 'file-saver';
@Component({
  selector: 'app-apoyos',
  templateUrl: './apoyos.component.html',
  styleUrls: ['./apoyos.component.scss']
})
export class ApoyosComponent {

  habilitaragregar: boolean = false;
  dspAgregar: boolean = false;
  loading: boolean = false;
  dspVerMas: boolean = false;

  num_anio: number = 0;
  years: number[] = [];
  id_ciudad_principal: number = 0;
  id_semana: number = 0;
  dsblstCiudades: boolean = true;
  imp_total: number = 0;
  num_dias: number = 0;
  des_puesto: string = '';

  nombre_chofer: string = '';
  imp_salario_base: number = 0;
  imp_salario_ims: number = 0;

  porcentaje: number = 0;

  mostrarbtnLimpiarFiltro: boolean = false;
  dsblstColaboradores: boolean = false;
  dspDesgloce: boolean = false;
  dbsReportes: boolean = false;
  porcentaje_capturado: number = 0;
  clv_activo: number = 0;
  id_editar: number = 0;


  anio_filtro: any;

  meses: IMes[] = [
    { num_mes: 0, nombre: "TODOS LOS MESES" },
    { num_mes: 1, nombre: "Enero" },
    { num_mes: 2, nombre: "Febrero" },
    { num_mes: 3, nombre: "Marzo" },
    { num_mes: 4, nombre: "Abril" },
    { num_mes: 5, nombre: "Mayo" },
    { num_mes: 6, nombre: "Junio" },
    { num_mes: 7, nombre: "Julio" },
    { num_mes: 8, nombre: "Agosto" },
    { num_mes: 9, nombre: "Septiembre" },
    { num_mes: 10, nombre: "Octubre" },
    { num_mes: 11, nombre: "Noviembre" },
    { num_mes: 12, nombre: "Diciembre" }];



  reactiveApoyos: FormGroup = new FormGroup({
    idx: new FormControl(0),
    cod_chofer: new FormControl(0, [Validators.min(1),Validators.required]),
    id_apoyo: new FormControl(0, [Validators.min(1), Validators.required]),
    fec_inicio: new FormControl(null, [Validators.required]),
    fec_fin: new FormControl(null, [Validators.required]),
    num_duracion: new FormControl(0),
    id_ciudad_principal: new FormControl(0),
    imp_fijo: new FormControl(0),
    desc_motivo_apoyo: new FormControl('', [Validators.maxLength(500), Validators.required]),
    clv_activo: new FormControl(0),
    id_acotecimiento: new FormControl(0),
    selectedSemana: new FormControl([], [Validators.min(1), Validators.required]),
  });


  user: ICredencialUsuario = {};
  @ViewChild('dt1') dt!: Table;
  lstCiudad: ICiudadPrincipalHM[] = [];
  lstCiudad2: ICiudadPrincipalHM[] = [];
  lstCiudades: ICiudadHM[] = [];
  lstChoferes: InombreCompletoChoferciudadprincipal[] = [];

  lstapoyo: IApoyo[] = [];

  lstChoferesDisponibles: IChoferDisponible[] = [];
  lstSemanas: ISemanasHappyMile[] = [];
  lstCatApoyos: ICatApoyo[] = [];

  uploadedFiles: any[] = [];
  selectedSemana: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };
  selectedSemanaReporte: ISemanasHappyMile = {
    idx: 0,
    id_semana: 0,
    nom_semana: '',
    anio: 0,
    fec_inicio: '',
    fec_fin: '',
    clv_activo: 0
  };
  lstdias: IDiasApoyo[] = [{

    id: 1,
    num_dias: 3,
    desc_dias: 'Local'
  }, {
    id: 2,
    num_dias: 5,
    desc_dias: 'Foraneo'
  }];




  constructor(private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private auth: AuthService,
    private semanaService: SemanaService,
    private apoyoservice: ApoyoService

  ) {
    this.initializeYears();
    this.num_anio = new Date().getFullYear();
    this.cargarInformacionUsuario();
  }
  ngOnInit(): void {
    this.cargarCiudades();
    this.cargaListaChoferes();
    this.cargarcatApoyos();
    this.cargarListadoDeSemanasAnio();
    this.inicializarFechas();

  }

  cargarInformacionUsuario() {
    this.user = this.auth.user;
  }

  //iniciliza los años para el filtro
  initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 5; year--) {
      this.years.push(year);
    }
  }
  cargarListadoDeSemanasAnio_cast(num_anio: any) {
    let anio_cast: any = 0;
    if (num_anio instanceof Date) {
      // will execute
      anio_cast = num_anio.getFullYear();
    } else {
      anio_cast = num_anio;
    }
    this.lstSemanas = [];
    this.semanaService.listarSemanas(anio_cast).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error al cargar Semanas HM</b>. Contacte al Administrador.');
      });
  }
  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    // this.anio = getYear;
    this.anio_filtro = getYear;
  }

  cargarListadoDeSemanasAnio() {
    this.lstSemanas = [];
    this.semanaService.listarSemanas(this.num_anio).subscribe((resp) => {
      this.lstSemanas = resp;
      setTimeout(() => {
      },
        600);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> al cargar Información. Contacte al Administrador.');
      });
  }

  // metodos para eñ filtro para fechas
  getTargetDescription(ruta: ISemanasHappyMile) {
    let fec_inicio = new Date(ruta.fec_inicio);
    let fec_fin = new Date(ruta.fec_fin);
    return this.formatearFechas(fec_inicio) + ' | ' + this.formatearFechas(fec_fin);
  }
  formatearFechas(fecha: Date): string {
    let fecha_Cast = '';
    var getYear = new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date(fecha).toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date(fecha).toLocaleDateString('es-MX', { day: '2-digit' });
    fecha_Cast = getDay + '/' + getMonth + '/' + getYear;
    return fecha_Cast;
  }



  //carga la lista inicial de semanas
  cargarCiudades() {
    this.dsblstCiudades = true;
    this.loading = true;
    this.apoyoservice.getListadoCiudadesPrincipales().subscribe((resp) => {
      this.lstCiudad = resp.slice(); // Crear una copia independiente
      this.lstCiudad2 = resp.slice(); // Crear una copia independiente
      const index = this.lstCiudad.findIndex(item => item.nombre === 'TODAS LAS CIUDADES');
      if (index !== -1) {
        this.lstCiudad.splice(index, 1);
      }
      this.lstCiudad.push({ id: 0, nombre: 'TODAS LAS CIUDADES', clave_estado: '' })
      setTimeout(() => {
        this.loading = false;
        this.dsblstCiudades = false;
      }, 1000);
    },
      (error) => {
        this.loading = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Ciudades');
      });
  }










  //carga la lista incial de choferes
  cargaListaChoferes() {
    this.dsblstColaboradores = true;
    this.lstChoferes = [];
    this.apoyoservice.getListadoChoferesGeneral().subscribe((resp) => {
      this.lstChoferes = resp;
      setTimeout(() => {
        this.dsblstColaboradores = false;
      }, 100);
    },
      (error) => {
        this.dsblstColaboradores = false;
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar Listado de Colaboradores');
      });
  }


  cargarcatApoyos() {
    this.apoyoservice.getCatApoyos().subscribe((resp) => {
      this.lstCatApoyos = resp;
      setTimeout(() => {
      }, 100);
    },
      (error) => {
        this.mostrarDialogoInformativoSinInformacion('<b>Error</b> En cargar catalogo de apoyos');
      })
  }





  // -----------filtra y carga la informacion ----
  FiltrarInformacion() {
    this.habilitaragregar = true;
    this.loading = true;
    if (this.id_ciudad_principal == null || typeof this.id_ciudad_principal === 'undefined' || !this.selectedSemana || this.selectedSemana.id_semana == null || typeof this.selectedSemana.id_semana === 'undefined') {
      this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> Debe de seleccionar una semana para poder filtrar');
      this.loading = false;
    } else {
      // this.mostraragregar= true;
      this.apoyoservice.getListadoapoyo(this.num_anio, this.selectedSemana.id_semana, this.id_ciudad_principal, this.user.cod == undefined ? '0999' : this.user.cod).subscribe(
        (resp) => {
          this.lstapoyo = resp;
          if (this.lstapoyo.length === 0) {
            this.loading = false;
            this.mostrarDialogoInformativoSinInformacionsinadmi('<b>No Existe</b> Registros para la fecha seleccionado');
          } else {
            // this.imp_total_genral = 0;
            // // Iterar sobre la lista y sumar los valores de los campos
            // this.lstIncapacidades.forEach((item) => {
            //   this.imp_total_genral += item.bono_recomendacion;
            // });
            // Finalizar el loading después de 800 ms2
            setTimeout(() => {
              this.loading = false;
            }, 800);

          }
        },
        (error) => {
          this.mostrarDialogoInformativoSinInformacionsinadmi('<b>ERROR</b> en Cargar Movimientos ');
          this.loading = false;
        }
      );
    }
  }


  crearNuevo() {

    this.reactiveApoyos.controls['desc_motivo_apoyo'].enable();
    this.dspAgregar = true;
    this.reactiveApoyos.controls['clv_activo'].setValue(1);
    this.reactiveApoyos.controls['cod_chofer'].enable();
    this.reactiveApoyos.controls['id_ciudad_principal'].disable();
    this.reactiveApoyos.controls['num_duracion'].disable();
    this.reactiveApoyos.controls['idx'].setValue(0);
  }
  cancelarAgregar() {
    this.dspAgregar = false;
    this.id_editar = 0;
    this.reactiveApoyos.controls['num_duracion'].setValue(0);
    this.reactiveApoyos.controls['cod_chofer'].setValue(0);
    this.reactiveApoyos.controls['id_ciudad_principal'].setValue(0);
    this.reactiveApoyos.controls['fec_inicio'].setValue('');
    this.reactiveApoyos.controls['id_apoyo'].setValue(0);
    this.reactiveApoyos.controls['imp_fijo'].setValue(0);
    this.reactiveApoyos.controls['id_acotecimiento'].setValue(0);
    this.reactiveApoyos.controls['desc_motivo_apoyo'].setValue('');
    this.reactiveApoyos.controls['selectedSemana'].setValue('');



  }

  GuardarApoyo() {
    let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === this.reactiveApoyos.controls['cod_chofer'].value);
    let nombreChofer = choferSeleccionado ? choferSeleccionado.nombre_completo : ''

    if (this.reactiveApoyos.controls['id_apoyo'].value === 1 || this.reactiveApoyos.controls['id_apoyo'].value === 6 ||
      this.reactiveApoyos.controls['id_apoyo'].value === 7 || this.reactiveApoyos.controls['id_apoyo'].value === 8|| this.reactiveApoyos.controls['id_apoyo'].value === 9||this.reactiveApoyos.controls['id_apoyo'].value ===10) {
      this.reactiveApoyos.controls['fec_inicio'].setValue(this.reactiveApoyos.controls['selectedSemana'].value.fec_inicio)
    }

    let data_enviar: IPostApoyo = {
      idx: this.reactiveApoyos.controls['idx'].value,
      cod_chofer: this.reactiveApoyos.controls['cod_chofer'].value,
      id_apoyo: this.reactiveApoyos.controls['id_apoyo'].value,
      desc_motivo_apoyo: this.reactiveApoyos.controls['desc_motivo_apoyo'].value,
      id_ciudad_principal: this.reactiveApoyos.controls['id_ciudad_principal'].value,
      fec_inicio: this.reactiveApoyos.controls['fec_inicio'].value,
      clv_activo: this.reactiveApoyos.controls['clv_activo'].value,
      num_duracion: this.reactiveApoyos.controls['num_duracion'].value,
      imp_fijo: this.reactiveApoyos.controls['imp_fijo'].value,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,
    };
    this.confirmationService.confirm({
      message: '¿Está seguro de guardar la <b>APOYO</b>  del Colaborador <b>' + nombreChofer + '</b>?',
      header: 'Confirmación antes de guardar apoyo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.apoyoservice.postApoyo(data_enviar).subscribe((resp) => {
          //const resultado = Array.isArray(resp) ? resp[0] : resp;
          if (resp.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se registro correctamente' });
            this.dspAgregar = false;
            this.FiltrarInformacion();
            this.cancelarAgregar();
          }
          else if (resp.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
            // this.dspCliente = false;
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }



  EditarApoyo(data: IApoyo) {
    const selectedWeek = this.lstSemanas.find(semana => semana.id_semana === data.id_semana);
    if (data.id_apoyo != 1) {
      this.reactiveApoyos.controls['imp_fijo'].enable();
    } else {
      this.reactiveApoyos.controls['imp_fijo'].disable();
    }
    this.dspAgregar = true;
    this.id_editar = 1;
    this.reactiveApoyos.controls['desc_motivo_apoyo'].enable();
    this.reactiveApoyos.controls['fec_fin'].disable();
    this.reactiveApoyos.controls['id_ciudad_principal'].disable();
    this.reactiveApoyos.controls['cod_chofer'].disable();
    this.reactiveApoyos.controls['num_duracion'].disable();

    let x = new Date(data.fec_inicio);
    let y = x.toISOString()
    let z = new Date(data.fec_fin);
    let w = z.toISOString()

    this.reactiveApoyos.controls['idx'].setValue(data.idx);
    this.reactiveApoyos.controls['id_apoyo'].setValue(data.id_apoyo);
    this.reactiveApoyos.controls['cod_chofer'].setValue(data.cod_chofer);
    this.reactiveApoyos.controls['num_duracion'].setValue(data.num_duracion);
    this.reactiveApoyos.controls['id_ciudad_principal'].setValue(data.id_ciudad_principal);
    this.reactiveApoyos.controls['desc_motivo_apoyo'].setValue(data.desc_motivo_apoyo);
    this.reactiveApoyos.controls['fec_inicio'].setValue(y.substring(0, 10));
    this.reactiveApoyos.controls['fec_fin'].setValue(w.substring(0, 10));
    this.reactiveApoyos.controls['clv_activo'].setValue(4);

    this.reactiveApoyos.controls['selectedSemana'].setValue(selectedWeek);

    if (this.reactiveApoyos.controls['num_duracion'].value == 0) {
      this.reactiveApoyos.controls['imp_fijo'].setValue(data.imp_total_apoyo);
    }

    if (this.reactiveApoyos.controls['id_apoyo'].value == 1) {
      this.reactiveApoyos.controls['imp_fijo'].disable();
    }

    if (this.reactiveApoyos.controls['id_apoyo'].value == 2 && data.num_duracion == 3) {
      this.reactiveApoyos.controls['id_acotecimiento'].setValue(1);
    } else {
      this.reactiveApoyos.controls['id_acotecimiento'].setValue(2);
    }




  }


  verMas(data: IApoyo) {
    this.dspVerMas = true;
    this.nombre_chofer = data.nom_chofer;
    this.reactiveApoyos.controls['desc_motivo_apoyo'].setValue(data.desc_motivo_apoyo);
    this.reactiveApoyos.controls['desc_motivo_apoyo'].disable();
  }
  verMenos() {
    this.dspVerMas = false;
    this.reactiveApoyos.controls['desc_motivo_apoyo'].setValue('');
  }





  EliminarApoyo(data: IApoyo) {

    let data_enviar: IPostApoyo = {
      idx: data.idx,
      cod_chofer: data.cod_chofer,
      id_apoyo: data.id_apoyo,
      desc_motivo_apoyo: data.desc_motivo_apoyo,
      id_ciudad_principal: data.id_ciudad_principal,
      fec_inicio: data.fec_inicio,
      clv_activo: this.clv_activo = 3,
      num_duracion: data.num_duracion,
      imp_fijo: data.imp_total_apoyo,
      cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,

    };
    this.confirmationService.confirm({
      message: '¿Está seguro que desea eliminar el <b>APOYO</b>  del Colaborador <b>' + data.nom_chofer + '</b>?',
      header: 'Confirmación de eliminacion de apoyo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.apoyoservice.postApoyo(data_enviar).subscribe((resp) => {
          //const resultado = Array.isArray(resp) ? resp[0] : resp;
          if (resp.clv_estatus == 1) {
            this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se elimino correctamente' });
            this.dspAgregar = false;
            this.FiltrarInformacion();
            this.cancelarAgregar();
          }
          else if (resp.clv_estatus == -2) {
            this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
            // this.dspCliente = false;
          }
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
            break;
        }
      }
    });
  }


  AprobarApoyo(data: IApoyo) {
    if (data.clv_activo == 2) {
      this.messageService.add({
        key: 'msj',
        severity: 'warn',
        summary: 'Cancelado',
        detail: 'El apoyo ya fue autorizada'
      });
    } else {

      let data_enviar: IPostApoyo = {
        idx: data.idx,
        cod_chofer: data.cod_chofer,
        id_apoyo: data.id_apoyo,
        desc_motivo_apoyo: data.desc_motivo_apoyo,
        id_ciudad_principal: data.id_ciudad_principal,
        fec_inicio: data.fec_inicio,
        clv_activo: this.clv_activo = 2,
        num_duracion: data.num_duracion,
        imp_fijo: data.imp_total_apoyo,
        cod_usuario: this.user.cod == undefined ? '0999' : this.user.cod,

      };

      this.confirmationService.confirm({
        message: '¿Está seguro que desea aprobar el <b>APOYO</b>  del Colaborador <b>' + data.nom_chofer + '</b>?',
        header: 'Confirmación de edición de estatus',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.apoyoservice.postApoyo(data_enviar).subscribe((resp) => {
            // const resultado = Array.isArray(resp) ? resp[0] : resp;
            if (resp.clv_estatus == 1) {
              this.messageService.add({ key: 'msj', severity: 'success', summary: 'Exito', detail: 'Se edito correctamente' });
              this.dspAgregar = false;
              this.FiltrarInformacion();
              this.cancelarAgregar();
            }
            else if (resp.clv_estatus == -2) {
              this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: resp.messageDetails });
            }
          });
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
              break;
            case ConfirmEventType.CANCEL:
              this.messageService.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operación cancelada' });
              break;
          }
        }
      });
    }
  }



  changedp(event: any) {
    let eve = event.value;
    let choferSeleccionado = this.lstChoferes.find(chofer => chofer.cod_chofer === eve);
    if (choferSeleccionado) {
      this.reactiveApoyos.controls['id_ciudad_principal'].setValue(choferSeleccionado.id_ciudad_principal);


    }
  }

  changeimp(event: any) {
    this.reactiveApoyos.controls['num_duracion'].setValue(0);
    let eve = event.value;
    let imp = this.lstCatApoyos.find(apoyo => apoyo.id_apoyo === eve);
    if (imp) {
      this.reactiveApoyos.controls['id_apoyo'].setValue(imp.id_apoyo);
      this.reactiveApoyos.controls['imp_fijo'].setValue(imp.imp_fijo);
      if (imp.id_apoyo != 2) {
        this.reactiveApoyos.controls['num_duracion'].setValue(imp.num_dias);
      }

      if (imp.id_apoyo == 1) {
        this.reactiveApoyos.controls['imp_fijo'].disable();
      } else {
        this.reactiveApoyos.controls['imp_fijo'].enable();
      }
    }
  }


  changedias(event: any) {
    this.reactiveApoyos.controls['num_duracion'].setValue(0);
    let eve = event.value;
    let imp = this.lstdias.find(apoyo => apoyo.id === eve);
    if (imp) {
      this.reactiveApoyos.controls['num_duracion'].setValue(imp.num_dias);
    }
  }




  mostrarDialogoInformativoSinInformacionsinadmi(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }

  mostrarDialogoInformativoSinInformacion(mensaje_usuario: string) {
    let mensaje_completo = mensaje_usuario;
    this.confirmationService.confirm({
      message: mensaje_completo,
      header: 'Informativo',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      reject: () => {
        this.messageService.add({ key: 'msj', severity: 'info', summary: 'Confirmación', detail: 'Usuario informado de la situación.' });
      },
      key: "sinInformacionDialog"
    });
  }





  abrirModalReportes() {
    this.dbsReportes = true;

  }

  deshabilitarbtnexportar: boolean = false;
  ButtonExportExcel: boolean = false;
  loading_reporte1: boolean = false;
  id_ciudad_principalrpt: number = 0;
  exportarExcel() {

    this.deshabilitarbtnexportar = true;
    // if (this.id_ciudad_principalrpt == null || typeof this.id_ciudad_principalrpt === 'undefined' || !this.id_ciudad_principalrpt || this.selectedSemanaReporte.id_semana == null || typeof this.selectedSemanaReporte.id_semana === 'undefined') {
    //   this.mostrarDialogoInformativoSinInformacion('<b>Informativo</b> Debe seleccionar una semana para poder filtrar');
    //   this.deshabilitarbtnexportar = false;
    // } else {
    this.apoyoservice.rptApoyo(this.selectedSemanaReporte.anio, this.selectedSemanaReporte.id_semana, this.id_ciudad_principalrpt,)
      .subscribe((resp: IApoyo[]) => {
        if (resp.length === 0 || typeof resp === 'undefined') {
          this.mostrarDialogoInformativoSinInformacion('<b>Aviso</b> debes de seleccionar parametros validos con registros existentes para poder exportar la informacion del Catalogo(Excel)');

          this.deshabilitarbtnexportar = false;
        } else {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(resp);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array"
            });

            this.saveAsExcelFile(excelBuffer, "Reporte_apoyo" + '_' + 'semana' + '_' + this.selectedSemanaReporte.id_semana + '_' + 'añio' + '_' + this.selectedSemanaReporte.anio);
          });
          setTimeout(() => {
            this.ButtonExportExcel = false;
            this.deshabilitarbtnexportar = false;
            this.cancelarModalReportes()
          }, 1000);
        }
      },
        (error) => {
          this.ButtonExportExcel = false;
          this.deshabilitarbtnexportar = false;

          this.messageService.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'Error en emitir el reporte de Estatus de Facturación.' });
        });
    //}


  }




  cancelarModalReportes() {
    this.id_ciudad_principalrpt = 0;
    this.dbsReportes=false;
    this.selectedSemanaReporte = {
      idx: 0,
      id_semana: 0,
      nom_semana: '',
      anio: 0,
      fec_inicio: '',
      fec_fin: '',
      clv_activo: 0
    };
  }



  saveAsExcelFile(buffer: any, fileName: string): void {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + "_exportar_" + dateFormat + EXCEL_EXTENSION);
  }


  /*Filtro General para la informacion de tabla*/
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  onUpload(event: { files: any; }) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
  }
}
